.share_link_modal_component {
    .copy_clipboard {
        background-color: var(--color-light);
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }
}