.carousel_property_component {
    .carousel.carousel-slider .control-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0.5em;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1em;
        background-color: rgba(139, 139, 139, 0.664);
        color: black;
        font-weight: bold;
        height: 2.3em;
        z-index: 1;
        font-size: 1.3em;
        user-select: none;
        opacity: 1;

        margin: 0 2em;

        &.control-prev::before {
            border-right: 8px solid white;
        }

        &.control-next::before {
            border-left: 8px solid white;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, .8);
        }
    }

    .carousel_container {
        background-color: var(--color-light);
        position: relative;
        user-select: none;

        .thumbs-wrapper {
            margin-top: 0.5em;

            .thumbs {
                padding-left: 0;

                .thumb {

                    height: 70px;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        height: 100%;
                    }

                    &.selected {
                        border: 2px solid var(--color-orange);
                    }
                }
            }
        }




        .line_icons {
            position: absolute;
            right: 1em;
            top: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .line_icon {
                background-color: white;
                padding: 0.5em;
                border-radius: 3px;
                display: flex;
                align-items: center;
                opacity: 0.6;
                margin-right: 0.3em;

                .material-symbols-outlined {
                    color: var(--color-orange);
                }

                .hidden_text {
                    // display: none;
                    margin-left: 0.2em;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 1;

                    .hidden_text {
                        display: block;
                    }
                }
            }
        }


        .carousel_image_container {
            width: 100%;
            max-height: 60vh;
            user-select: none;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--color-light);

            @media screen and (max-width: 768px) {
                max-height: 40vh;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

    }

    .zoomed_carousel {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        user-select: none;

        .bg_closer {
            position: fixed;
            top: 0;
            left: 0;
            color: white;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .8);
            z-index: -1;

            text-align: end;
        }

        .container_carousel_zoom {
            height: 90%;
            width: 90%;
            margin: auto;

            img {
                max-width: 90vw;
                max-height: 90vh;
                object-fit: contain;
            }

        }
    }

    @media screen and (max-width: 768px) {
        margin: 0 -.75rem;
    }
}