.details_property_component {

    .business_container {
        padding: 1em;
        border-radius: 5px;
    }

    .title_detail{
        font-size: 1.85rem;
        font-weight: bold;
        color: var(--color-orange);
    }

    .main_info {
        .main_info_item {
            h2 {
                display: flex;
                flex-direction: column;

                &.inverted {
                    flex-direction: column-reverse;
                }

                .title_info {
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                    font-weight: bold;
                    color: var(--color-blue);
                    text-transform: capitalize;
                }

                .content_info {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
        }

    }


    .description_container {
        // background-color: var(--color-light);
        // padding: 1em;
        margin-top: 1em;
        font-size: 1rem;
        border-radius: 5px;
    }

    .aditional_container {
        .aditional_item {
            display: flex;
            align-items: center;

            h3 {
                font-size: 1rem;
                font-size: medium;
                font-weight: 500;
            }

            svg {
                margin-right: 0.5em;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .main_info .main_info_container{
            padding: 0 1em;
        }

        .description_container {
            background-color: var(--color-light);
            padding: 1em;
            border-radius: 5px;
            
        }
        
    }
}