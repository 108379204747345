.paginator_component {
  .o-option-paginator {
    height: 1.8em;
    width: 1.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 400;
    border-radius: 5px;
    background-color: white;
    margin: 0 0.2em;
  }

  .o-option-paginator:hover,
  .o-option-paginator.active {
    background-color: var(--color-main);
    color: white;
  }
}