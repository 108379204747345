.modal_custom_component {
    .modal_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .finish_modal_container_button {
            background-color: var(--color-orange);
            border-color: var(--color-orange);

            &:hover {
                background-color: var(--color-orange-hover);
                border-color: var(--color-orange-hover);
            }
        }

    }
}