.contact_card_component {
    .card-title {
        font-size: 1rem;
        font-weight: 600;
        color: #ffffff;
        gap: 0.5rem;
        background-color: var(--color-blue);
        padding: 0.5rem 1rem;
        border-radius: 5px 5px 0 0;

        h2 {
            font-size: 1rem;
        }
    }

    .card-body {
        padding: 1rem;
        background-color: var(--color-light);
        border-radius: 0 0 5px 5px;
    }
}