:root {
    --color-main: #E76C30;
    --color-orange: #E76C30;
    --color-orange-hover: #ff8434;
    --color-secondary: #4C959E;
    --color-blue: #4C959E;
    --color-secondary-light: #95d1d8;
    --color-light: #f5f5f5;
    --color-lighter: #f9f9f9;
    --color-gray: #c8c6c6;
    --color-green: #4CAF50;
}

.dropdown_hover {
    position: relative;

    &:hover {
        cursor: pointer;

        &>.dropdown_content {
            display: flex;
            flex-direction: column;
            position: absolute;
            background-color: white;
            top: 90%;
            z-index: 1000;
            right: 0;
            width: fit-content;

            &.right {
                left: 100%;
                top: 0;
                right: inherit;
            }

            a {
                &:hover {
                    background-color: var(--color-main);
                    color: white;
                }

                padding: 1em;
                white-space: nowrap;
            }
        }
    }

    &>.dropdown_content {
        display: none;
    }
}

.hover {
    cursor: pointer;
}

.mbtn {
    padding: 0.2em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--color-main-dark);
    }

    &.btn_orange {
        background-color: var(--color-main);
        color: white;
    }

    &.btn_orange_outline {
        background-color: white;
        color: var(--color-main);
        border: solid thin var(--color-main);
    }

    &.btn_green_outline {
        background-color: white;
        color: var(--color-green);
        border: solid thin var(--color-green);
    }
}



.bg {
    &_light {
        background-color: var(--color-light);
    }

    &_secondary_light {
        background-color: var(--color-secondary-light);
    }

}

#root {

    .rs-slider-bar {
        background-color: white;
    }

    .rs-slider-handle::before,
    .rs-slider-bar .rs-slider-progress-bar {
        background-color: var(--color-main);
        border-color: var(--color-main);
    }
}

.mini_title_h2 {
    font-size: 1.1rem;
    width: 100%;
    line-height: 1.2rem;
}

.title_h1 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.4rem;
    width: 100%;
    margin-bottom: 0;
    color: var(--color-main);
    text-align: justify;
}

h1.text,
h2.text,
h3.text,
h4.text,
h5.text,
h6.text,
span.text,
text {

    &_color_main {
        color: var(--color-main);
    }

    &_color_secondary {
        color: var(--color-secondary);
    }

    &_bold {
        font-weight: bold;
    }

    &_small_2 {
        font-size: 0.7em;
    }

    &_ultra_small {
        font-size: 0.7rem;
    }

    &_capitalize {
        text-transform: capitalize;
    }
}

#root {
    .loader_preview {
        width: 100%;
        //background animated and like a loader
        background: linear-gradient(125deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.1) 100%);
        background-size: 200% 200%;
        animation: gradient 1.5s linear infinite;
        border-radius: 5px;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

}

body {
    a {
        color: currentColor;
        text-decoration: none;

        &:active,
        &:focus {
            text-decoration: none;
            color: currentColor;
        }
    }

}

.PhoneInput input,
.PhoneInput.PhoneInput--focus input {
    border: none;
}

.floating_button {
    position: fixed;
    bottom: 1em;
    right: 1em;
}

.floating_contact_mobile {
    position: fixed;
    right: 0;
    z-index: 1000;
    top: 50%;
    // transform: translateY(-50%);
    transform: translateX(36%) rotate(90deg);

    background-color: var(--color-main);
    color: white;
    padding: 0.3em 1em;
    display: flex;
    align-items: center;
    // right: 1rem;
    // bottom: 1rem;
    border-radius: 0 0 5px 5px;
    // border: solid 2rgb(255, 255, 255)ite;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    // border-radius: 5px;
    // width: 1em;
    // height: 1em;
    // padding: 1em;
    // border-radius: 100%;

    a:hover,
    a:active {
        text-decoration: none;
        color: white;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
}

form {
    .form-group.error {


        input,
        textarea,
        select {
            &::placeholder {
                color: #bf1650;
            }

            color: #bf1650;
            border: solid thin #bf1650;
            background-color: #bf165055;
        }

        &.error_group {
            .PhoneInput {

                .PhoneInputCountry::placeholder {
                    color: #bf1650;
                }

                color: #bf1650;
                border: solid thin #bf1650;
                background-color: #bf165055;
            }
        }

        aside {
            color: #bf1650;

            &::before {
                display: inline;
                content: "⚠ ";
            }
        }

        label {
            color: #bf1650;
        }
    }

    aside {
        color: #bf1650;
    }

    .password_input_wrapper {
        position: relative;

        .visibility_icon {
            position: absolute;
            top: 50%;
            right: 0.5em;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
}