.detailed_property_page {
    overflow-y: hidden;

    .title_detailed {
        font-size: 1.875rem;
        font-weight: bold;
        margin: 2rem 0 0.125rem 0;
        color: var(--color-orange);
    }

    .contact_property {
        position: relative;
        overflow: hidden;

        .contact_property_static {
            overflow-y: auto;
        }

        @media screen and (max-width: 768px) {
            overflow: visible;

            .contact_property_static {
                overflow-y: visible;
                margin-top: 1em !important;
            }

        }
    }

    @media screen and (max-width: 768px) {
        .title_detailed {
            font-size: 1.5rem;
            margin-bottom: 0.5em;
            margin-top: 1.5em;
            line-height: 1.5rem;
        }
        
    }
}