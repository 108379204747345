.favorites_page {

    &.no_content {
        .title_container {
            padding: 1em 0;
            // color: var(--color-orange);
        }

        h1 {
            // color: var(--color-main);
            font-size: 1.5em;
            font-weight: 700;
            line-height: 1.5em;
            margin-bottom: 0;
        }

        h2 {
            // color: var(--color-main);
            font-size: 1.2em;
            font-weight: 700;
            line-height: 1.3em;
        }

        .mini_title {
            font-size: 1.3em;
            width: 100%;
        }

        .title {
            font-size: 3.4em;
            font-weight: 700;
            line-height: 1.1em;
            width: 100%;
            margin-bottom: 0;
            color: var(--color-main);
            text-align: justify;
        }

        .description {
            font-size: 1.3em;
            font-weight: 700;
            line-height: 1.3em;
            text-align: justify;
        }

        .btn_properties {
            font-size: 1.3em;
        }
    }

    &.yes_added {

        .h5 {
            font-weight: bold;
            color: var(--color-main);

        }
        .h6 {
            font-weight: bold;
        }

        .card_property_component .data_container:not(.has_tag) {
            border: solid 1px var(--color-gray);
        }

    }

    @media screen and (max-width: 768px) {
        &.no_content {

            .mini_title,
            .title,
            .description {
                text-align: center;
            }

            .mini_title {
                display: none;
            }

            .title {
                font-size: 2.5em;
                margin-top: 0.4em;
                margin-bottom: 1em;
            }
        }

    }
}