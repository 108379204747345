.card_item {
    h3 {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.4rem;

        .value_text {
            font-weight: bold;
        }

        .title_text{
            font-size: 0.8em;
        }
    }

}