.need_loan_component{
    .buttons_container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.625rem 0;
    }

    .loan_title{
        font-size: 1.875rem;
        font-weight: bold;
        margin: 0.625rem 0;
        color: var(--color-orange);
    }

    .value_text{
        font-size: 1.875rem;
        font-weight: bold;
        color: var(--color-orange);
    }

    .type_credit_button{
        font-size: 1.2rem;
        font-weight: bold;
        padding: 1rem;
        cursor: pointer;
        text-align: end;
        user-select: none;

        @media (max-width: 768px) {
            width: 50%;
            text-align: center;
         }

        &.type_credit_button_unselected{
            background-color: white;
            color: #121212;
            border-right: 2.5px solid #e5e7eb;
        }

        &.type_credit_button_selected{
            background-color: var(--color-orange);
            color: var(--color-orange);
            background-color: #fff6eb;
            border-right: 2.5px solid var(--color-orange);
            border-radius: 0.5rem 0 0 0.5rem;
        }

        @media (max-width: 768px) {
            &.type_credit_button_selected {
              border-right: none;
              border-bottom: 2.5px solid var(--color-orange);
              border-radius: 0.5rem 0.5rem 0 0;
            }

            &.type_credit_button_unselected {
              border-right: none;
              border-bottom: 2.5px solid #e5e7eb;
            }
         }
        
    }

    .loan-note{
        padding: 1rem;
        border: #e5e7eb 1px solid;
        border-radius: 0.5rem;
    }

    .loan-btn{
        text-align: center;
    }
}