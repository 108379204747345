.no_results_properties_component {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding-bottom: 1em;

    img{
        max-width: 300px;
        width: 100%;
    }

    .no_results_title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 20px;
    }

    .no_results_subtitle {
        font-size: 1rem;
        margin-top: 10px;
    }
}