.range_picker_component{

    &>.d-flex{
        .flex_grow-1{
            min-width: 45%;
        }
    }
    input.form-control{
        font-weight: bolder;
        color: var(--color-gray-dark);
    }
}