.header_property_component {

    .header_property_component__title{
        font-size: 1.85rem;
        font-weight: bold;
        // color: var(--color-orange);
        line-height: 1.2em;
        margin-bottom: 0.5em;

        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 1.1em;
        }
    }
    .line_icons {
        display: flex;
        justify-content: center;
        align-items: center;

        .line_icon {
            background-color: white;
            padding: 0.5em;
            border-radius: 3px;
            display: flex;
            align-items: center;
            margin-right: 0.3em;
            // border: solid thin #e76d3085;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

            .material-symbols-outlined {
                color: var(--color-orange);
            }

            .hidden_text {
                // display: none;
                margin-left: 0.2em;
            }

            &:hover {
                cursor: pointer;

                .hidden_text {
                    display: block;
                }
            }
        }
    }

    .tag_container {
        border-radius: 10px;
        font-weight: 600;
        padding: 0.4em 0.5em;
        color: white;
        width: fit-content;
    }
}