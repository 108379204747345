.maps_close_properties_component {
    position: relative;
    @media screen and (max-width: 768px) {
        margin: 0 -.75rem;
    }
    .line_icon {
        position: absolute;
        top: 1em;
        right: 1em;
        background-color: white;
        padding: 0.5em;
        border-radius: 3px;
        display: flex;
        align-items: center;
        opacity: 0.9;
        margin-right: 0.3em;
        z-index: 1;

        .hidden_text {
            // display: none;
            margin-left: 0.2em;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;

            .hidden_text {
                display: block;
            }
        }
    }
}