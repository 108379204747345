.properties_page {
    .btn_filters{
        display: flex;
        margin: 1em 0;
        padding: 0.5em 1em;
        align-items: center;
        color: var(--color-orange);
        height: fit-content;
        flex-wrap: nowrap;
        text-wrap: nowrap;
    }

    .btn_set_filters{
        display: flex;
        padding: 0.5em 1em;
        align-items: center;
        color: white;
        height: fit-content;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        justify-content: space-around;
    }

    .dropdown_grid {
        .white_space_content{
            height: 0.5em; 
            width: 100%;
        }
        .dropdown_content {
            left: 0;
            width: 100%;
        }
    }
}