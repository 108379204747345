.terms_and_condition_page {
    .container{
        max-width: 1000px;
    }
    .o-banner-container {
        min-height: 6.25rem;
        display: flex;
        align-items: center;

        .o-banner-information-title {
            font-size: 2.5em;
            font-family: roboto;
            color: white;
            padding: 4em, 0, 4em, 0;
        }

        .o-banner-information-text {
            margin-top: 2em;
            font-size: 1em;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            color: #272727;
        }
    }

    .policy_and_terms_container {
        text-align: justify;
        font-size: 1.1em;

        h2 {
            font-size: 1.3em;
            margin-top: 1em;
            margin-bottom: 0.5em;
            font-weight: bold;
            color: var(--color-main);
        }

        p {
            margin-bottom: 0.5em;
        }

        br {
            margin-bottom: 1em;
            height: 1em;
        }
    }
}