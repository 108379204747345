.card_property_component {

    height: fit-content;
    color: currentColor;
    text-decoration: none;
    position: relative;

    .like_icon {
        position: absolute;
        right: 20px;
        top: 10px;
    }

    &:hover {
        text-decoration: none;
        color: currentColor;

        .data_container {
            overflow: hidden;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
        }
    }

    &>.has_tag {
        border: solid thin transparent;
    }

    &.list {
        .img_preview_container {
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                background-size: cover;
                background-position: center;
                object-fit: cover;
                min-width: 100%;
                max-height: 260px;
                min-height: 100%;
            }

            // max-height: 260px;
        }

        .text_description {
            max-height: 3em;
            overflow: hidden;
            margin-bottom: 0.5em;

            &>* {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }

    }

    &.grid {
        .data_container {
            height: 100%;
        }

        .img_preview_container {
            width: 100%;
            height: 200px;
            overflow: hidden;

            img {
                background-size: cover;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-position: center;
            }
        }
    }

    .container_img {
        position: relative;

        .tag_property {
            border-radius: 10px;
            font-weight: 800;
            position: absolute;
            left: 25px;
            top: 10px;
            padding: 0.3em 0.5em;
            // background-color: white;
            // color: var(--color-main);
            color: white;
            // color: white;
            // background-color: var(--color-main);
            // border: solid 2px black;
        }
    }

    .data_container {
        border-radius: 5px;
        background-color: white;

        .property_title {
            font-size: 1em;
            margin: 0 0 1em 0;
            line-height: 1.5em;
        }

        .business_container {
            display: flex;
            flex-direction: column-reverse;

            .type_business {
                font-size: 0.8em;
                font-weight: lighter;
            }

            .price_container {
                font-weight: bold !important;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}