.help_search_property_component{

    padding: 1rem;
    border: #e5e7eb 1px solid;
    border-radius: 0.5rem;
    margin: 0.625rem 0;

    .help_title{
        font-size: 1.875rem;
        font-weight: bold;
        margin: 0.625rem 0;
        color: var(--color-orange);
        font-size: 1.7rem;
    }
    
}