.footer_component {

    *{
        text-align: center;
    }

    a,
    .normal_text {
        font-weight: lighter;
        text-decoration: none;
        // color: var(--color-secondary);
        color: currentColor;
        text-decoration: none;
    }
    .suggest_box {
        .text_ultra_small {
            line-height: 1.4em;
            font-size: 0.9rem;

        }
    }

    .contact_info {

        .block_info{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            & {
                text-align: center;
            }
            

            @media screen and (min-width: 768px) {
                justify-content: start;
                align-items: start;
                text-align: left;

                & {
                    text-align: left;
                }
            }
        }

        h3, h4 {
            font-size: 1rem;
            line-height: 1.5rem;
            display: flex;
            margin: 0.5em 0;

            svg {
                margin-right: 0.5rem;
            }
        }

        .h5.text_color_main {
            color: var(--color-main);
            font-size: 1.2rem;
        }

        .social_tag {
            margin-right: 1em;
        }
    }

    .last_line {
        border-top: solid 1px #b3b3b3;
        margin-top: 1em;
        padding: 1em 0;
    }

    @media screen and (min-width: 768px) {
        * {text-align: left;}
    }

    &.inverted_bg {
        background-color: var(--color-lighter);
        .footer_info {
            background-color: var(--color-light);
        }

    }
}