.contact_property_component {
    // background-color: transparent;
    // border: solid 1px #dedede;

    @media screen and (max-width: 768px) {
        background-color: var(--color-light);
        margin: 0 -.75rem;
        padding: 0 .75rem;
    }

    &>.bg_light {
        padding: 0.5em 1em;
        border-radius: 5px;

    }

    .contact_box_call {
        
        .mbtn {
            padding: 0.6em 0.5em;

            border: none;
            // border-bottom: solid var(--color-orange) 1px;
            // border-bottom: solid #26272971 1px;
            color: #262729;
            font-weight: 500;

            svg {
                fill: var(--color-orange);
            }

            &.btn_green_outline {
                svg {
                    fill: var(--color-green);
                }
            }
        }
    }

    .contact_form_property_component {
        .form-control {
            border: none;
        }
    }
}