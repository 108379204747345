.side_filter_component{
    .line_filter{
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--color-main);
        background-color: #efefef;
        padding: 0.5em;
        border-radius: 0.5em;

        &>.filter_title{
            color: var(--color-main);
            font-size: 1.2em;
        }
        &>div{
            margin-bottom: 1em;
        }
    }
}