.header_component {
    box-shadow: 0 -5px 10px 2px #cecece;

    .dropdown_hover {
        .dropdown_content {
            .nav-link {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

        }
    }

    @media screen and (max-width: 768px) {
        .dropdown_hover {
            &>.dropdown_title {
                text-align: center;
            }

            &>.dropdown_content {
                border-radius: 1.25rem;
                display: none;
                background-color: white;

            }

            &.open {
                display: block;
                border-bottom: solid thin var(--color-main);
                border-top: solid thin var(--color-main);

                &>.dropdown_content {
                    text-align: center;
                    display: block;
                    position: inherit;
                }

                &>.dropdown_content.right {
                    display: flex;
                    flex-direction: column;
                    left: 0;
                }
            }
        }
    }
}