.delete_filter_component {
    padding: 1em 0;
    display: flex;
    flex-wrap: wrap;

    .delete_filter_item {
        text-transform: capitalize;
        color: var(--color-orange);
        padding: 0.5em 1em;
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        border: solid thin var(--color-gray);
        margin-right: 0.5em;
        margin-bottom: 0.5em;

        h2{
            margin: 0;
        }

        svg {
            margin-left: 0.5em;
            color: var(--color-gray);
        }

        .color_fill {
            width: 1em;
            height: 1em;
            border-radius: 100%;
        }
    }
}

@media (min-width: 700px) {
    .delete_filter_component {
        padding-left: 6px;
    }
}
