.o-information-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.o-error-text{
    text-align: center;
    font-size: 9.375em;
    color: #E76C30;
}

.o-error-title{
    text-align: center;
    font-size: 1.75em;
    color: #E76C30;
}

.o-error-description{
    text-align: start;
    font-size: 1.25em;
    color: #707070;
}

.o-error-container{
    min-height: 85vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.o-house-image{
    width: 30em;
}

.o-link{
    color: #E76C30;
    font-size: 1.25em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.o-information-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}