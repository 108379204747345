.layout_component {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .scrolled_layout {
        flex-grow: 1;
        overflow-y: auto;
        width: 100vw;
        overflow-x: hidden;
        }
}